import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

export default function GEENumberOfClassesStatisticsContent({
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
  orderByLabelName = false,
  localeKey = 'number_of_classes_content',
}) {
  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
  });
  const data = _.get(statisticsData, 'data');
  const loading = _.get(statisticsData, 'loading');

  const locale = localStorage.getItem('locale') || 'es';

  const mainYear = _.last(activeYear);

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    }
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const isLoading = loadingClassTreeData || loading;

  const getTotalAreaByClassesData = () => {
    const areas = _.get(_.find(data, { year: mainYear }), 'areas');

    let parsedSeries = _.map(areas, (item) => {
      const { pixelId, value } = item;

      const nodeData = _.find(classTreeLevelsList, { pixelValue: pixelId });

      if (!nodeData) {
        return null;
      }

      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      return {
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        position: orderByLabelName ? Number(label.match(/\d/g).join('')) : null,
        color: _.get(nodeData, 'color'),
        total: value
      };
    });

    const sortedSeries = _.sortBy(parsedSeries, orderByLabelName ? 'position' : 'name');

    return {
      series: sortedSeries,
    };
  };

  useEffect(() => {
    if (!loading && (!data || _.isEmpty(data))) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && data) {
      setChartsData({
        'totalAreaByClasses': getTotalAreaByClassesData(),
      });
    }
  }, [data, loading, loadingClassTreeData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const numberOfClasses = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];

    const parsedSeries = _.map(series, (serie) => {
      return {
        name: serie.name,
        value: serie.total,
        itemStyle: {
          color: serie.color
        },
      };
    });

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id={ `mapbiomas.${ localeKey }.pie_chart_title` } /></h2>
        <PieChart
          serie={{
            title: 'Total',
            data: parsedSeries
          }}
        />
      </div>
    );
  };

  const areaByNumberOfClasses = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];

    const parsedSeries = _.map(series, (serie) => {
      return {
        name: serie.name,
        value: serie.total,
        itemStyle: {
          color: serie.color
        },
      }
    });

    const hideXAxisLabel = _.size(_.get(_.sample(series), 'name')) > 5;

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id={ `mapbiomas.${ localeKey }.bar_chart_title` } /></h2>
        <BarChart
          hideXAxisLabel={ hideXAxisLabel }
          categories={ _.map(series, 'name') }
          series={ [
            {
              name: 'Total',
              data: parsedSeries
            }
          ] }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];
    const parsedRows = _.map(series, (item) => {
      return {
        ...item,
        data: [_.get(item, 'total')]
      };
    });

    const numberOfClassesChart = {
      title: <FormattedMessage id={ `mapbiomas.${ localeKey }.bar_chart_title` } />,
      columns: ['Total'],
      rows: parsedRows
    };

    onShowData([
      numberOfClassesChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { numberOfClasses() }
        { areaByNumberOfClasses() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { isLoading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p>Nenhum resultado encontrado.</p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
