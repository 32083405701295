import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_CLASS_TREE_DATA } from '../graphql/queries/general';

import getCustomClient from '../lib/getCustomClient';

import mapClassTreeToAPIParams from '../data/mapClassTreeToAPIParams';

const API_URL = 'https://dev-degradacao.geodatin.com/api/v1/degradation/brazil/maps/';

export function parseCrossingParams(values) {
  const crossingParams = _.reduce(_.omit(values, ['__typename', 'parsedParams']), (result, value, key) => {
    if (value) {
      result[_.get(mapClassTreeToAPIParams[key], 'paramKey')] = value;
    }

    return result;
  }, {});

  return qs.stringify(crossingParams, { arrayFormat: 'repeat' });
}

export default function useCustomMapLayer(params) {
  const {
    activeYear,
    activeBaseClassTreeNodeIds,
    activeClassTreeNodeIds,
    activeClassTreeOptionValue,
    degradationType,
    degradationCrossingValues,
    degradationFragmentationIsolationValue,
    territories,
  } = params;
  const urlParams = mapClassTreeToAPIParams[activeClassTreeOptionValue];
  const isOnCrossingMode = degradationType === 'crossing';
  const degradationCrossingParsedParams = _.get(degradationCrossingValues, 'parsedParams');
  const hasBaseValueSelected = !_.isEmpty(activeBaseClassTreeNodeIds) || isOnCrossingMode;
  const { data: defaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    },
    client: getCustomClient('8.0'),
  });
  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: activeClassTreeOptionValue
    },
    client: getCustomClient('8.0'),
    skip: !activeClassTreeOptionValue,
  });
  const defaultClassTreeNodes = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const classTreeNodes = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  let parsedParams = {
    initiative: 'colombia',
    year: activeYear,
    pixelValues: _.map(activeClassTreeNodeIds, (nodeId) => (
      _.get(_.find(classTreeNodes, { id: nodeId }), 'pixelValue')
    )),
    colors: _.map(activeClassTreeNodeIds, (nodeId) => (
      _.get(_.find(classTreeNodes, { id: nodeId }), 'color')
    )),
    territoryIds: _.map(territories, 'id')
  };

  if (hasBaseValueSelected || degradationFragmentationIsolationValue) {
    const pixelValues = _.compact(_.map(activeClassTreeNodeIds, (nodeId) => (
      _.get(_.find(defaultClassTreeNodes, { id: nodeId }), 'pixelValue')
    )));
    const colors = _.map(pixelValues, (value) => (
      _.get(_.find(defaultClassTreeNodes, { pixelValue: value }), 'color')
    ));

    parsedParams = {
      ...parsedParams,
      pixelValues,
      colors,
    };
  }

  if (hasBaseValueSelected && !isOnCrossingMode) {
    parsedParams = {
      ...parsedParams,
      [_.get(urlParams, 'paramKey')]: _.map(activeBaseClassTreeNodeIds, (nodeId) => (
        _.get(_.find(classTreeNodes, { id: nodeId }), 'pixelValue')
      ))
    };
  }

  let mapUrl = `${ API_URL }${ _.get(urlParams, 'path') }?${ qs.stringify(parsedParams, { arrayFormat: 'repeat' }) }`;


  if (isOnCrossingMode && degradationCrossingParsedParams) {
    mapUrl = `${ API_URL }multiple?${ qs.stringify(parsedParams, { arrayFormat: 'repeat' }) }&${ degradationCrossingParsedParams }`;
  } else if (degradationFragmentationIsolationValue) {
    mapUrl = `${ API_URL }isolation?${ qs.stringify(parsedParams, { arrayFormat: 'repeat' }) }&${ degradationFragmentationIsolationValue }`;
  }

  useEffect(() => {
    if (_.includes(mapUrl, 'pixelValues')) {
      setLoading(true);
      fetch(mapUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          const layerURL = _.get(data, 'url');
          setUrl(layerURL);
          setLoading(false);
        });
    }
  }, [mapUrl]);

  return {
    loading,
    url
  };
}
