export default [
	{
			"index": 1,
			"key": "biome",
			"labelKey": "biome",
			"color": "#222222",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "IDEAM (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 2,
			"key": "country",
			"labelKey": "country",
			"color": "#FDBF6F",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "DANE - IGAC (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 3,
			"key": "department",
			"labelKey": "department",
			"color": "#EB2F06",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "DANE - IGAC (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 4,
			"key": "municipality",
			"labelKey": "municipality",
			"color": "#947438",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "DANE - IGAC (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 5,
			"key": "watershed_otto_1",
			"labelKey": "watershed_otto_1",
			"color": "#1E90FF",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "IDEAM (ajustado por FGA )"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 6,
			"key": "watershed_otto_2",
			"labelKey": "watershed_otto_2",
			"color": "#FF6347",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "IDEAM (ajustado por FGA )"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 7,
			"key": "watershed_otto_3",
			"labelKey": "watershed_otto_3",
			"color": "#87CEEB",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "IDEAM (ajustado por FGA )"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 8,
			"key": "region",
			"labelKey": "region",
			"color": "#FBEC00",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "IDEAM (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 9,
			"key": "raisg",
			"labelKey": "raisg",
			"color": "#758F50",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "RAISG"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 10,
			"key": "peasant_reserve_zone",
			"labelKey": "peasant_reserve_zone",
			"color": "#76BF65",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "ANT (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2018
					}
			],
			"downloadUrl": null
	},
	{
			"index": 11,
			"key": "indigenous_territory",
			"labelKey": "indigenous_territory",
			"color": "#FC795B",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "ANT (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2018
					}
			],
			"downloadUrl": null
	},
	{
			"index": 12,
			"key": "forest_reserves_law_2",
			"labelKey": "forest_reserves_law_2",
			"color": "#008000",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "Minambiente (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2022
					}
			],
			"downloadUrl": null
	},
	{
			"index": 13,
			"key": "ramsar_site",
			"labelKey": "ramsar_site",
			"color": "#DFFF00",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "RAMSAR (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2022
					}
			],
			"downloadUrl": null
	},
	{
			"index": 14,
			"key": "moorland",
			"labelKey": "moorland",
			"color": "#61633C",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "Minambiente (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2020
					}
			],
			"downloadUrl": null
	},
	{
			"index": 15,
			"key": "car",
			"labelKey": "car",
			"color": "#636363",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "Minambiente (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2019
					}
			],
			"downloadUrl": null
	},
	{
			"index": 16,
			"key": "community_councils",
			"labelKey": "community_councils",
			"color": "#8F6C50",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "ANT (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2020
					}
			],
			"downloadUrl": null
	},
	{
			"index": 17,
			"key": "tropical_dry_forest",
			"labelKey": "tropical_dry_forest",
			"color": "#7D912D",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "Minambiente (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2015
					}
			],
			"downloadUrl": null
	},
	{
			"index": 18,
			"key": "protected_natural_area",
			"labelKey": "protected_natural_area",
			"color": "#FF8C00",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "RUNAP (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 19,
			"key": "sub_national_protected_natural_area",
			"labelKey": "sub_national_protected_natural_area",
			"color": "#008B8B",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "RUNAP (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 20,
			"key": "glaciers",
			"labelKey": "glaciers",
			"color": "#69C8D1",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "RGI (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2002
					}
			],
			"downloadUrl": null
	},
	{
			"index": 21,
			"key": "glaciers_complex",
			"labelKey": "glaciers_complex",
			"color": "#227780",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "RUNAP (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2024
					}
			],
			"downloadUrl": null
	},
	{
			"index": 22,
			"key": "biogeographic_choco",
			"labelKey": "biogeographic_choco",
			"color": "#9C8B5A",
			"descriptionData": [
					{
							"labelKey": "source",
							"label": "Fonte",
							"value": "WWF (Ajustado por FGA)"
					},
					{
							"labelKey": "year",
							"label": "Ano",
							"value": 2001
					}
			],
			"downloadUrl": null
	}
];
