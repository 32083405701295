import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../components/BarChart';
import BaseValuesChart from '../../components/BaseValuesChart';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

export default function GEEAnnualStatisticsContent({
  clientType,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
}) {
  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
  });
  const data = _.get(statisticsData, 'data');
  const loading = _.get(statisticsData, 'loading');

  const locale = localStorage.getItem('locale') || 'es';
  const intl = useIntl();

  const mainYear = _.last(activeYear);
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: getCustomClient(clientType)
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const filteredActiveClassTreeNodeIds = _.filter(activeClassTreeNodeIds, (nodeId) => {
    const nodeData = _.find(classTreeLevelsList, { id: nodeId });
    const childrenIds = _.get(nodeData, 'childrenIds');
    
    if (_.isEmpty(childrenIds)) {
      return true;
    } else {
      return !childrenIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);
    }
  });

  const getTotalAreaByClassesData = () => {
    const years = _.map(data, 'year');
    const pixelValues = _(data)
      .map(({ areas }) => _.map(areas, 'pixelId'))
      .flatten()
      .uniq()
      .value();

    const parsedSeries = _.map(pixelValues, (pixelValue) => {
      const nodeData = _.find(classTreeLevelsList, { pixelValue });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      const parsedData = _.map(years, (year) => {
        const yearData = _.find(data, { year });
        const yearAreaTotal = _.get(yearData, 'areaTotal');
        const areasData = _.get(yearData, 'areas');
        const pixelAreaValue = _.get(_.find(areasData, { pixelId: pixelValue }), 'value');
        const pixelPercentageValue = (pixelAreaValue / yearAreaTotal) * 100;


        return {
          value: pixelAreaValue,
          percentage: pixelPercentageValue,
        };
      });

      return {
        nodeId: _.get(nodeData, 'id'),
        pixelValue,
        stack: 'year',
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data: _.map(parsedData, 'value'),
        percentageData: _.map(parsedData, 'percentage'),
      };
    });
    const sortedSeries = _.sortBy(parsedSeries, 'name');
    const filteredSeries = _.filter(sortedSeries, ({ nodeId }) => _.includes(filteredActiveClassTreeNodeIds, nodeId));
    const percentageSeries = _.map(filteredSeries, (serie) => ({ ...serie, data: serie.percentageData }));

    return {
      categories: years,
      series: filteredSeries,
      filteredSeries: filteredSeries,
      percentageSeries: percentageSeries,
    };
  };

  useEffect(() => {
    if (!loading && (!data || _.isEmpty(data))) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && data) {
      setChartsData({
        'totalAreaByClasses': getTotalAreaByClassesData(),
      });
    }
  }, [data, loading, loadingClassTreeData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const areaByPeriod = () => {
    const data = _.get(statisticsData, 'data');
    const total = _.get(_.find(data, { year: mainYear }), 'areaTotal') || 0;
    const maxItem = _.maxBy(data, 'areaTotal');
    const maxValue = _.get(maxItem, 'areaTotal') || 0;
    const maxYear = _.get(maxItem, 'year');
    const averageValue = _.meanBy(data, 'areaTotal') || 0;
    const minItem = _.minBy(data, 'areaTotal');
    const minValue = _.get(minItem, 'areaTotal') || 0;
    const minYear = _.get(minItem, 'year');
    const averagePercentage = (averageValue / maxValue) * 100;
    const minPercentage = (minValue / maxValue) * 100;

    return (
      <BaseValuesChart
        title={ `${intl.formatMessage({id: 'mapbiomas.statistics.area.title'})} - ${ mainYear }` }
        totalTitle="Total"
        total={ total }
        maxValue={ maxValue }
        maxYear={ maxYear }
        averageValue={ averageValue }
        averagePercentage={ averagePercentage }
        minValue={ minValue }
        minYear={ minYear }
        minPercentage={ minPercentage }
      />
    );
  };

  const totalAreaByClasses = () => {
    const categories = _.get(chartsData, 'totalAreaByClasses.categories') || [];
    const series = _.get(chartsData, 'totalAreaByClasses.filteredSeries') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.anual_statistics_content.area_title" /></h2>
        <BarChart
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const percentageAreaByClasses = () => {
    const categories = _.get(chartsData, 'totalAreaByClasses.categories') || [];
    const series = _.get(chartsData, 'totalAreaByClasses.percentageSeries') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.anual_statistics_content.proportion_title" /></h2>
        <BarChart
          isPercentage
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const totalAreaByClassesChartData = _.get(chartsData, 'totalAreaByClasses');
    const totalAreaByClassesChartDataCategories = _.get(totalAreaByClassesChartData, 'categories') || [];
    const totalAreaByClassesChartDataSeries = _.get(totalAreaByClassesChartData, 'series') || [];

    const totalAreaByClassesChart = {
      title: <FormattedMessage id="mapbiomas.anual_statistics_content.area_title" />,
      columns: totalAreaByClassesChartDataCategories,
      rows: totalAreaByClassesChartDataSeries
    };

    const percentageAreaByClassesChartDataCategories = _.get(totalAreaByClassesChartData, 'categories') || [];
    const percentageAreaByClassesChartDataSeries = _.get(totalAreaByClassesChartData, 'percentageSeries') || [];

    const percentageAreaByClassesChart = {
      title: <FormattedMessage id="mapbiomas.anual_statistics_content.proportion_title" />,
      columns: percentageAreaByClassesChartDataCategories,
      rows: percentageAreaByClassesChartDataSeries
    };

    onShowData([
      totalAreaByClassesChart,
      percentageAreaByClassesChart
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { areaByPeriod() }
        { totalAreaByClasses() }
        { percentageAreaByClasses() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
