import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import numbro from 'numbro';
import qs from 'qs';

import { FormattedMessage } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

import BarChart from '../../components/BarChart';
import LineChart from '../../components/LineChart';
import PieChart from '../../components/PieChart';
import DegradationMultipleBarChart from '../../components/DegradationMultipleBarChart';

import styles from '../../StatisticsBox.module.scss';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import { GET_CLASS_TREE_DATA } from '../../../../../../../../graphql/queries/general';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import mapClassTreeToAPIParams from '../../../../../../../../data/mapClassTreeToAPIParams';

const API_URL = 'https://dev-degradacao.geodatin.com/api/v1/degradation/brazil/statistics/';

const CLASS_TREE_KEYS_STACKED = [
  'degradation_fire_frequency',
  'degradation_fire_time',
  'degradation_secondary_vegetation_age',
];

const formatNumber = (value, mantissa = 0) => {
  if (_.isNil(value)) { return '-'; }

  return numbro(value).format({
    thousandSeparated: true,
    mantissa
  });
};

export default function DegradationMultipleContent({
  activeYear,
  baseParams,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'es';
  const formatMessage = useFormatMessage();
  const activeBaseClassTreeNodeIds = _.get(baseParams, 'activeBaseClassTreeNodeIds');
  const hasBaseValueSelected = !_.isEmpty(activeBaseClassTreeNodeIds);
  const classTreeKey = _.get(baseParams, 'activeClassTreeOptionValue');
  const isStacked = _.includes(CLASS_TREE_KEYS_STACKED, classTreeKey);
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const degradationCrossingValues = _.get(baseParams, 'degradationCrossingValues');
  const degradationCrossingParsedParams = _.get(degradationCrossingValues, 'parsedParams');
  const territoryIds = _.map(_.get(baseParams, 'territories'), 'id');
  const urlParams = mapClassTreeToAPIParams[classTreeKey];
  const statisticsParamKey = !hasBaseValueSelected ? _.get(urlParams, 'paramKey') : 'classId';
  const ChartComponent = !isStacked ? LineChart : BarChart;

  const [chartsData, setChartsData] = useState(null);
  // console.log("🚀 ~ chartsData:", chartsData)
  const [emptyData, setEmptyData] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: defaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    },
    client: getCustomClient('8.0')
  });
  const { data: classTreeData, loading: loadingClassTreeData,  } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: classTreeKey
    },
    client: getCustomClient('8.0')
  });
  const defaultClassTreeNodes = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const classTreeNodes = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  // useEffect(() => {
  //   const sampleData = _.get(baseData, 'frequencyAccumulatedGrouped');

  //   if ((!loading && errorBaseData) || _.isEmpty(sampleData)) {
  //     setEmptyData(true);
  //   } else {
  //     setEmptyData(false);
  //   }

  //   if (!loadingClassTreeData && !loading && baseData) {
  //     setChartsData({
  //       'accumulatedAreaFromAllPeriod': getAccumulatedAreaFromAllPeriodData(),
  //     });
  //   }
  // }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const getChartData = (data, valueKey) => {
    const years = _.sortBy(_.uniq(_.map(data, 'year')));
    const nodeValues = _.sortBy(_.uniq(_.map(data, statisticsParamKey)));
    const nodes = hasBaseValueSelected ? defaultClassTreeNodes : classTreeNodes;

    const parsedSeries = _.map(nodeValues, (nodeValue) => {
      const nodeData = _.find(nodes, { pixelValue: nodeValue });
      const values = _.map(years, (year) => {
        return _.get(_.find(data, { year, [statisticsParamKey]: nodeValue }), valueKey);
      });

      let label = nodeValue;

      if (hasBaseValueSelected) {
        label = _.chain(nodeData)
          .get('i18nStrings')
          .find({ language: locale })
          .get('stringValue')
          .value();
      }
      
      return {
        nodeId: _.get(nodeData, 'id'),
        stack: isStacked ? 'year' : undefined,
        name: label,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data:values
      };
    });

    return {
      series: _.compact(parsedSeries),
      categories: years,
    };
  };

  useEffect(() => {
    if (!classTreeNodes) {
      return;
    }

    // const crossingParams = _.reduce(_.omit(degradationCrossingValues, '__typename'), (result, value, key) => {
    //   if (value) {
    //     result[_.get(mapClassTreeToAPIParams[key], 'paramKey')] = value;
    //   }

    //   return result;
    // }, {});
    const parsedParams = {
      initiative: 'colombia',
      year: activeYear,
      territoryIds: territoryIds,
      // ...(crossingParams)
    };

    let statisticsUrl = 'https://dev-degradacao.geodatin.com/api/v1/degradation/brazil/statistics/multiple';
    statisticsUrl += `?${ qs.stringify(parsedParams, { arrayFormat: 'repeat' }) }`;
    statisticsUrl += `&${ degradationCrossingParsedParams }`;

    // console.log("🚀 ~ DegradationMultipleContent ~ parsedParams:", parsedParams)
    // if (hasBaseValueSelected) {
    //   const nodeValue = _.first(activeBaseClassTreeNodeIds);
    //   const nodeData = _.find(classTreeNodes, { id: nodeValue });
    //   const pixelValue = _.get(nodeData, 'pixelValue');

    //   statisticsUrl += `/${ pixelValue }`;
    // }

    // statisticsUrl += `?territoryIds=${ _.join(territoryIds) }`;

    setLoading(true);
    fetch(statisticsUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("🚀 ~ .then ~ data:", data)
        setChartsData({
          'totalData': _.get(data, 'total'),
          'classData': _.get(data, 'degradationByClass'),
          // 'areaChartData': getChartData(_.get(data, 'absoluteTimeSeries'), 'areaHa'),
          // 'percentageChartData': getChartData(_.get(data, 'relativeTimeSeries'), 'percentage'),
        });
        setLoading(false);
      });
  }, [degradationCrossingParsedParams, _.join(territoryIds), activeYear]);

  const filterActiveSeries = (series) => {
    return _.filter(series, ({ nodeId }) => _.includes(activeClassTreeNodeIds, nodeId));
  };

  const totalChart = () => {
    const data = _.get(chartsData, 'totalData') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>Área de vegetação nativa afetada pelos vetores</h2>
        <h3 className={ styles.bigNumber }>{ formatNumber(_.get(data, 'areaHa') / 1000000, 2) } Mha</h3>
        <p className={ styles.bigNumberInfo }>({ formatNumber(_.get(data, 'percentage'), 1) }% do total)</p>
        <PieChart
          unit="ha"
          serie={{
            title: 'Totais',
            data: [
              {
                name: 'Área afetada',
                value: _.get(data, 'areaHa'),
                itemStyle: {
                  color: '#f9ca24'
                },
              },
              {
                name: 'Área não afetada',
                value: _.get(data, 'referenceAreaHa') - _.get(data, 'areaHa'),
                itemStyle: {
                  color: '#6ab04c'
                },
              },
            ]
          }}
        />
      </div>
    );
  };

  const areaByClasses = () => {
    const data = _.get(chartsData, 'classData') || [];
    const colors = [
      '#35003c',
      '#55ff4a',
      '#403fd9',
      '#49d239',
      '#002eaa',
      '#e4c900',
      '#00a0ff',
      '#c76a00',
      '#fba8ff',
      '#ffba90',
    ];
    const categories = _.map(data, (item) => {
      const node = _.find(defaultClassTreeNodes, { pixelValue: item.classId });

      return _.chain(node)
        .get('i18nStrings')
        .find({ language: locale })
        .get('stringValue')
        .value();
    });
    const parsedSeriesAData = _.map(data, (item, index) => {
      const node = _.find(defaultClassTreeNodes, { pixelValue: item.classId });

      return {
        type: 'classValue',
        value: item.areaHa,
        itemStyle: {
          color: _.get(node, 'color') || colors[index],
        },
      };
    });
    const parsedSeriesA = {
      name: 'Área',
      stack: 'value',
      data: parsedSeriesAData,
      type: 'bar',
    };
    const parsedSeriesBData = _.map(data, (item) => {
      return {
        type: 'referenceValue',
        value: item.referenceAreaHa - item.areaHa,
        realValue: item.referenceAreaHa,
        itemStyle: {
          color: '#DEDEDE',
          decal: {
            color: 'rgba(0, 0, 0, 0.5)',
            dashArrayX: [1, 0],
            dashArrayY: [1, 10],
            symbolSize: 1,
            rotation: Math.PI / 6,
          },
        },
      };
    });
    const parsedSeriesB = {
      name: 'Área de referência',
      stack: 'value',
      data: parsedSeriesBData,
      type: 'bar',
    };
    const series = [parsedSeriesA, parsedSeriesB];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.anual_statistics_content.area_title" /></h2>
        <DegradationMultipleBarChart
          categories={ categories }
          series={ series }
          mantissa={ 1 }
        />
      </div>
    );
  };

  const handleShowData = () => {
    onShowData([
      _.get(chartsData, 'percentageChartData'),
    ]);
  };

  const renderData = () => {
    // if (emptyData) {
    //   return null;
    // }

    return (
      <Fragment>
        { totalChart() }
        { areaByClasses() }
        {/*
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div> */}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className={ styles.fireModeContentBoxExtra }>
        <div
          className={ styles.fireModeContentBoxTextWrapper }
          dangerouslySetInnerHTML={{
            __html: formatMessage('mapbiomas.warning.degradation_fragmentation.multiple.statistics_note')
          }}
        />
      </div>
      { (loading || loadingClassTreeData) &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { !loading && _.get(chartsData, 'totalData') && renderData() }
    </Fragment>
  );
}
