import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';

import { useIntl, FormattedMessage } from 'react-intl';

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../components/BarChart';

import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

import styles from '../../StatisticsBox.module.scss';

const formatNumber = (value) => {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function GEEAccumulatedStatisticsContent({
  activeYear,
  baseParams,
  localeKey = 'burned',
  statisticsDataIsVisible,
  onShowData,
}) {
  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
  });
  const data = _.get(statisticsData, 'data');
  const loading = _.get(statisticsData, 'loading');

  const theme = useTheme();

  const intl = useIntl();

  const fromYear = _.first(activeYear) || 1985;
  const toYear = _.isArray(activeYear) ? _.last(activeYear) : activeYear;
  const years = _.range(fromYear, toYear + 1);

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const getAccumulatedAreaFromAllPeriodData = () => {
    const categories = years || [];
    const values = _.map(_.reverse(data), 'areaTotal') || [];

    return {
      title: intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.title'}),
      columns: categories,
      rows: [
        {
          name: 'Total',
          data: values,
        }
      ]
    };
  };

  useEffect(() => {
    if (!loading && (!data || _.isEmpty(data))) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loading && data) {
      setChartsData({
        'accumulatedAreaFromAllPeriod': getAccumulatedAreaFromAllPeriodData(),
      });
    }
  }, [data, loading]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const totalSummary = () => {
    const values = _.get(chartsData, 'accumulatedAreaFromAllPeriod.rows[0].data') || [];
    const lastValue = _.last(values);

    return (
      <div className={ styles.sectionSmall }>
        <h2 className={ styles.secondaryTitle }>
          <span><FormattedMessage id={ `mapbiomas.statistics.${ localeKey }.accumulated.summary.title` } /></span>
        </h2>
        <ul className={ styles.infoList }>
          <li className={ styles.infoListItem }>
            <span className={ styles.infoListItemValue }>
              <b>{ formatNumber(lastValue) }</b>
              <small>ha</small>
            </span>
            <span className={ styles.infoListItemLabel }>
              <span><FormattedMessage id={ `mapbiomas.statistics.${ localeKey }.accumulated.summary.total.title` } /></span>
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const accumulatedAreaFromAllPeriod = () => {
    const categories = _.get(chartsData, 'accumulatedAreaFromAllPeriod.columns') || [];
    const values = _.get(chartsData, 'accumulatedAreaFromAllPeriod.rows[0].data') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.title'})}</span>
        </h2>
        <BarChart
          categories={ categories }
          series={ [
            {
              name: 'Total',
              color: _.get(theme, 'palette.primary.main'),
              data: values,
            }
          ] }
        />
      </div>
    );
  };

  const handleShowData = () => {
    onShowData([
      _.get(chartsData, 'accumulatedAreaFromAllPeriod')
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { totalSummary() }
        { accumulatedAreaFromAllPeriod() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
