import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import { useIntl, FormattedMessage } from 'react-intl';

import BarChart from '../../components/BarChart';
import BaseValuesChart from '../../components/BaseValuesChart';
import PieChart from '../../components/PieChart';

import InfoTooltip from '../../../../../../../../components/InfoTooltip';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function GEEFrequencyStatisticsContent({
  clientType,
  ageMode = false,
  fireMode = false,
  deforestationMode = false,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
}) {
  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
  });
  const data = _.get(statisticsData, 'data[0]');
  const loading = _.get(statisticsData, 'loading');

  const locale = localStorage.getItem('locale') || 'es';

  const intl = useIntl();

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: getCustomClient(clientType)
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const frequencyByAreaData = () => {
    const areas = _.get(data, 'areas');

    const parsedValues = _.map(areas, (item) => {
      const pixelValue = _.get(item, 'pixelId');
      const value = _.get(item, 'value');
      const nodeData = _.find(classTreeLevelsList, { pixelValue });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');

      return {
        name: label,
        value,
        itemStyle: {
          color: _.get(nodeData, 'color') || '#e74c3c'
        },
        position: _.get(nodeData, 'positionInTree[0]')
      };
    });

    return _.sortBy(parsedValues, 'position');
  };

  useEffect(() => {
    if (!loading && (!data || _.isEmpty(data))) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && data) {
      setChartsData({
        'frequencyByArea': frequencyByAreaData(),
      });
    }
  }, [data, loading, loadingClassTreeData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const frequencyPieChart = () => {
    const values = _.get(chartsData, 'frequencyByArea') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span><FormattedMessage id="mapbiomas.statistics.burned.frequency.area_frequency_pie.title" /></span>
        </h2>
        <PieChart
          serie={{
            title: intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.title'}),
            data: values
          }}
        />
      </div>
    );
  };

  const frequencyByArea = () => {
    const values = _.get(chartsData, 'frequencyByArea') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{ !ageMode ? intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.title'}) : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.title'}) }</span>

          <InfoTooltip text={intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.title_period_tooltip'})} />
        </h2>

        <BarChart
          categories={ _.map(values, 'name') }
          series={ [
            {
              name: 'Total',
              data: values,
            }
          ] }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const frequencyByAreaChartData = _.get(chartsData, 'frequencyByArea') || [];
    const frequencyByAreaChart = {
      title: !ageMode ? intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.title'}) : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.title'}),
      columns: _.map(frequencyByAreaChartData, 'name'),
      rows: [
        {
          name: 'Total',
          data: _.map(frequencyByAreaChartData, 'value'),
        }
      ]
    };

    onShowData([
      frequencyByAreaChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { frequencyPieChart() }
        { frequencyByArea() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
